<template>
  <div class="data-box">
    <div class="top-box">
      <label class="lable">{{ labelName }}</label>
    </div>
    <div class="data-info">
      <div class="first-row">
        <div class="info" v-for="item in data.slice(0, 3)" :key="item.label">
          <div class="val" :class="[item.path?'path':'', item.danger?'danger':'']" @click="$router.push({path: item.path,query:{active:item.active}})">{{ item.val }}</div>
          <div>{{ item.label }}</div>
        </div>
      </div>
      <div class="second-row">
        <div class="info" v-for="item in data.slice(3, 6)" :key="item.label">
          <div class="val" :class="[item.path?'path':'', item.danger?'danger':'']" @click="$router.push({path: item.path,query:{active:item.active}})">{{ item.val }}</div>
          <div>{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelName: null,
    data: Object
  }
}
</script>
<style lang="less" scoped>
.path {
  color: #2FB3E9;
  text-decoration: underline;
}
.danger {
  color: #ee0a24;
  text-decoration: underline;
}
</style>
