<template>
  <CountData :labelName="labelName1" :data="dataA"/>
  <CountData :labelName="labelName2" :data="dataB"/>
  <CountData :labelName="labelName3" :data="dataC"/>
</template>

<script>
const dateNow = new Date();
const year = dateNow.getFullYear();
const month = dateNow.getMonth() > 8 ? dateNow.getMonth()+1 : `0${dateNow.getMonth()+1}`;
const day = dateNow.getDate() > 9 ? dateNow.getDate() : `0${dateNow.getDate()}`;
import CountData from './components/countData'
import { homeInfos } from '../../assets/api/index'
export default {
  components: {
    CountData,
  },
  data() {
    return {
      labelName1: '今日货架数据',
      labelName2: '今日商城数据',
      labelName3: '报警信息',
      dataA: [],
      dataB: [],
      dataC: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      homeInfos({startDate: `${year}-${month}-${day}`, endDate: `${year}-${month}-${day}`}).then(res => {
        const infos = res.data.data
        this.dataA = [
          { label: '订单数/件数', val: `${infos.vmcSalesOrder}/${infos.vmcCount < 0 ? 0 : infos.vmcCount}`, path:'/site_count' },
          { label: '销售额', val: infos.vmcSales },
          { label: '实收额', val: infos.vmcReceipts },
          { label: '微信支付', val: infos.vmcWechatPay },
          { label: '余额支付', val: infos.vmcBalancePay },
          { label: '网点数/货架数', val: `${infos.netWorkCount > 0 ? infos.netWorkCount : 0}/${infos.vmcIdx > 0 ? infos.vmcIdx : 0}`, path:'/ratio' }
        ]
        this.dataB = [
          { label: '订单数', val: infos.shoppingOrders, path:'/shop_count' },
          { label: '销售额', val: infos.shoppingSales },
          { label: '实收额', val: infos.shoppingReceipts },
          { label: '微信支付', val: infos.shoppingWechatPay },
          { label: '余额支付', val: infos.shoppingBalancePay },
          { label: '待分配订单', val: infos.daijiedan > 0 ? infos.daijiedan : 0 , path: '/shop_order'}
        ]
        this.dataC = [
          { label: '离线机器数', val: infos.offline < 0 ? 0 : infos.offline, path: '/warning_count', active:1, danger: true },
          { label: '断货机器数', val: infos.outStock < 0 ? 0 : infos.outStock, path: '/warning_count', active:2, danger: true },
          { label: '滞留机器数', val: infos.retention < 0 ? 0 : infos.retention, path: '/warning_count', active:3, danger: true }
        ]
      })
    }
  }
}
</script>

<style lang="less">
.data-box {
  padding: 10px;
  min-height: 100px;
  .top-box {
    display: flex;
    justify-content: space-between;

    .lable {
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
    }

    .van-tabs {
      width: 240px;

      .van-tabs__nav {
        margin-right: 0;
      }
    }
  }

  .data-info {
    font-size: 14px;

    .info {
      height: 80px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #999;
      .val {
        font-size: 18px;
        font-weight: 600;
        line-height: 44px;
      }
    }

    .first-row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .info {
        width: calc(33% - 5px);
      }
    }
    .second-row {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;

      .info {
        width: calc(33% - 5px);
      }
    }
  }
}
</style>
